<template>
  <div class="mContact">
    <p class="title">学习软件支持各大平台</p>
    <p class="titleDes">随时随地，想学就学</p>
    <div class="qrcodeBox">
        <div class="qrcodeItem">
          <p class="qrcodeName">微信小程序</p>
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/wxapp_code.png" class="qrcodeImg">
          <p class="qrcodeDes">微信扫一扫，即可体验写字小程序</p>
        </div>
        <div class="qrcodeItem">
          <p class="qrcodeName">苹果版</p>
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/ios.png" class="qrcodeImg"  >
          <p class="phoneBtn" @click="url('https://itunes.apple.com/cn/app/%E6%B2%B3%E5%B0%8F%E8%B1%A1/id1436282840?mt=8')">点击下载App</p>
        </div>
        <div class="qrcodeItem">
          <p class="qrcodeName">安卓版</p>
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/android.png" class="qrcodeImg">
          <p class="phoneBtn"  @click="url('https://android.myapp.com/myapp/detail.htm?apkName=com.mobcoreapp&ADTAG=mobile')">点击下载App</p>
        </div>
        <div class="qrcodeItem">
          <p class="qrcodeName">iPad版</p>
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/mobile_code.png" class="qrcodeImg">
          <p class="phoneBtn"  @click="url('https://itunes.apple.com/us/app/%E6%B2%B3%E5%B0%8F%E8%B1%A1%E5%86%99%E5%AD%97/id1398168611?mt=8')">点击下载App</p>
        </div>
        <div class="qrcodeItem1" @click.stop="toPcWeb">
          <p class="qrcodeName">电脑板</p>
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/web_code.png" class="qrcodeImg1">
          <p class="qrcodeDes">点击此处跳转电脑版河小象</p>
        </div>
      </div>

    <!-- <m-footer class="footer"></m-footer> -->
  </div>
</template>

<script>
import clip from "..//utils/clipboard";
import mFooter from '../components/mFooter';
export default {
  components:{
    mFooter
  },
  name: 'mContact',
  data () {
    return {
    }
  },
  created() {
    this.setPageSize();
  },
  methods: {
       handleCopy(text, event) {
      clip(text, event);
      console.log("clicp");
    },
    url(url){
     window.location.href=url;
    },
    toPcWeb() {
      alert('请用电脑访问 hexiaoxiang.com 官网');
    },
    setPageSize() {
      !function(e,t){function n(){t.body?t.body.style.fontSize=12*o+"px":t.addEventListener("DOMContentLoaded",n)}function d(){var e=i.clientWidth/10;i.style.fontSize=e+"px"}var i=t.documentElement,o=e.devicePixelRatio||1;if(n(),d(),e.addEventListener("resize",d),e.addEventListener("pageshow",function(e){e.persisted&&d()}),o>=2){var a=t.createElement("body"),s=t.createElement("div");s.style.border=".5px solid transparent",a.appendChild(s),i.appendChild(a),1===s.offsetHeight&&i.classList.add("hairlines"),i.removeChild(a)}}(window,document);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mContact {
  margin-top: 1.52rem;
  padding: 0.89rem 0.4rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: auto;
  color: #fff;
}
.phoneBtn{
  width:120px;
height:30px;
background:rgba(19,180,254,1);
border-radius:30px;
 font-size: 0.34rem;
font-family:MicrosoftYaHei;
font-weight:400;
color:rgba(255,255,255,1);
line-height:30px;
margin-top: 8px;
}
.title {
  margin-bottom: 0.45rem;
  height: 0.75rem;
  line-height: 0.75rem;
  font-size: 0.75rem;
  color: #515558;
}
.titleDes {
  width: 100vw;
  height: 0.5rem;
  line-height: 0.5rem;
  font-size: 0.35rem;
  color: #515558;
}
.qrcodeBox {
  padding-bottom: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.qrcodeItem {
  margin-top: 1.2rem;
  flex: 0 0 6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 5rem;
  height: 6rem;
}
.qrcodeItem1 {
  margin-top: 1.2rem;
  flex: 0 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 4.75rem;
  height: 3rem;
}
.qrcodeName {
  width: 100%;
  height: 0.43rem;
  line-height: 0.43rem;
  font-size: 0.43rem;
  color: #515558;
}
.qrcodeImg {
  margin-top: 0.2rem;
  width: 4.75rem;
  height: 4.75rem;
}
.qrcodeImg1 {
  margin-top: 0.2rem;
  width: 1.89rem;
  height: 1.69rem;
}
.qrcodeDes {
  width: 100%;
  height: 0.27rem;
  line-height: 0.27rem;
  font-size: 0.27rem;
  color: #515558;
}
</style>
